<template>
	<div>
		<Header ref="Header"/>
		<img :src="menuPic" class="Big-title"/>
		<div class="container">
			<div class="about-list" v-html="content"></div>
		</div>
	</div>
</template>

<script>
	import  Header from '@/components/header.vue';
	export default {
		components:{Header},
		data() {
			return {
				menuPic:'',
				content:'',
			}
		},
		methods: {
			
		},
		mounted() {
			this.$refs.Header.setIndex(6);
			// 获取菜单图片
			this.$api.getMenuPic('关于我们').then(res=>{
				console.log(res,'菜单图片');
				this.menuPic=res.data[0].picture;
			})
			//获取内容
			this.$api.getSheetpage(5).then(res=>{
				console.log(res,'关于我们');
				this.content=res.data[1].content;
			})
		},
	};
</script>

<style>
</style>
